import { useState, useMemo, useCallback } from 'react';
import { HeadlessService, IPaginatedResponse, IMessage, IUserPreferenceSettings } from '@novu/headless';
// FetchResult, ISession,
type initCallback = {
  onSessionSuccess: () => void
  onUnseenCountChange: (count: number) => void
  onUnreadCountChange: (count: number) => void
  onSessionError: () => void
  onNotificationRecive: (message: IMessage) => void,
  userUuid: string
}

// https://local.farmapremium.es:3000/'https://novu-api-dev.farmacloud.io'/v1/widgets/session/initialize
const init = ({
  onSessionSuccess,
  onUnseenCountChange,
  onUnreadCountChange,
  onSessionError,
  onNotificationRecive,
  userUuid
} : initCallback) => {
  const service = new HeadlessService({
    applicationIdentifier: process.env.REACT_APP_NOVU_APP_ID || '',
    subscriberId: userUuid,// process.env.REACT_APP_NOVU_SUB_ID || '',
    backendUrl: process.env.REACT_APP_NOVU_BACKEND || undefined,
    socketUrl: process.env.REACT_APP_NOVU_SOCKET || undefined
  });
  service.initializeSession({
    listener: () => {}, 
    onSuccess: () => {
      /*
      profile
      token
      */
      service.listenUnseenCountChange({
        // this will run every time there's a change in the `unseen_count` in real-time
        listener: (unseenCount: number) => {
          onUnseenCountChange(unseenCount)
        },
      });

      service.listenUnreadCountChange({
        // this will run every time there's a change in the `unread_count` in real-time
        listener: (unreadCount: number) => {
          onUnreadCountChange(unreadCount)
        },
      });

      service.listenNotificationReceive({
        listener: (message: IMessage) => {
          onNotificationRecive(message)
        },
      })
      onSessionSuccess()
    },
    onError: (error) => {
      console.error(error);
      onSessionError()
    },
  });
  return service
}
export function useNotifications(userUuid: string) {
  /*
  notificationsPages --> paginated notifications, is interna use not exposed
  [
    [IMessage, IMessage, ...] --> page 0
    [IMessage, IMessage, ...] --> page 1
    [IMessage, IMessage, ....] --> page 2
  ]
  */
   const [notificationsPages, setNotificationsPages] = useState<IMessage[][]>([[]])
   
  // array all notifications [IMessage,IMessage, ... ]. Is a concat()  of notificationsPages
  const [notifications, setNotifications] = useState<IMessage[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [pageSize, setPageSize] = useState<number>()
  const [hasMore, setHasMore] = useState<boolean>(false)
  const [isAll, setIsAll] = useState<boolean>(false)
  const [unReadCount, setUnreadCount] = useState<number>(0)
  const [unSeenCount, setUnseenCount] = useState<number>(0)
  const [settings, setSettings] = useState<IUserPreferenceSettings[]>([])
    
  const headlessService = useMemo(() => {
    const headlessService = init({
      onSessionSuccess: () => {
        fetchUnseenCount()
        fetchUnreadCount()
        fetchNotifications({ page: 0 })
      },
      onUnseenCountChange: (count) => { setUnseenCount(count) },
      onUnreadCountChange: (count) => { setUnreadCount(count) },
      onNotificationRecive: () => {
        refreshAllNotificationPages()
      },
      onSessionError: () => {},
      userUuid
    })

    return headlessService
  
  }, [])
  
  const refreshAllNotificationPages = useCallback(() => {
    const promises: Promise<IPaginatedResponse>[]  = []
    notificationsPages.forEach((pageIter, index) => {
      promises.push(fetchNotifications({ page: index, updateNotification: false}))
    })
    Promise.all(promises).then(() => {
      updateNotificationList()
    })
  }, [notificationsPages, notifications, isAll])

  const updateNotificationList = useCallback(() => {
    let allNotifications: IMessage[] = []
    notificationsPages.forEach((pageIter) => {
      allNotifications = allNotifications.concat(pageIter)
    })
    setNotifications(allNotifications)
  }, [notificationsPages, notifications, isAll])

  const fetchNotifications = useCallback(async ({page = 0, updateNotification = true, all = false}: { page?: number, updateNotification?: boolean, all?: boolean }) => {
    return new Promise<IPaginatedResponse>((resolve, reject) => {
      headlessService.fetchNotifications({
        listener: ({ isLoading }) => {
          setIsLoading(isLoading)
        },
        onSuccess: ({page, data, totalCount, pageSize, hasMore}: IPaginatedResponse<IMessage>) => {        
          notificationsPages[page] = data
          setNotificationsPages(notificationsPages)
          setTotalCount(totalCount)
          setPageSize(pageSize) 
          setPage(page)
          setHasMore(hasMore)
          setIsLoading(false)
          setIsAll(all)
          if (updateNotification) updateNotificationList()
          resolve({page, data, totalCount, pageSize, hasMore})
        },
        query: (all) ? { } : {
          read: false
        },
        onError: () => reject(),
        page: page,
      });
    })
  }, [totalCount, notifications, page, isAll, hasMore, notificationsPages, isLoading, pageSize])

  const fetchAllNotifications = useCallback(() => {
    fetchNotifications({ all: true})
  }, [])

  const fetchUnReadNotifications = useCallback(() => {
    fetchNotifications({ all: false})
  }, [])

  const fetchUnseenCount = useCallback(() => {
    headlessService.fetchUnseenCount({
      listener: () => { },
      onSuccess: (data: { count: number }) => {
        setUnseenCount(data.count)
      },
      onError: () => { }
    });
  }, [])

  const fetchUnreadCount = useCallback(() => {
    headlessService.fetchUnreadCount({
      listener: () => {},
      onSuccess: (data: { count: number }) => {
        setUnreadCount(data.count)
      },
      onError: () => { },
    });
  }, [])

  /*
  ***** PUBLIC FUCNTIONS *****
  */
  const fetchMore = useCallback(() => {
    fetchNotifications({page: page +1, all: isAll})
  }, [isAll, page])

  const markAsReadInternal = useCallback((ids: string[]) => {
    headlessService.markNotificationsAsRead({
      listener: () => {},
      onSuccess: () => {},
      onError: () => {},
      messageId: ids,
    });
  }, [])

  const markAsRead = useCallback((message: IMessage) => {
    markAsReadInternal([message._id])
  }, [])

  const markAsAllRead = useCallback(() => {
    const ids = notifications.filter((n) => !n.read).map((n) => n._id)
    markAsReadInternal(ids)
  },[notifications])

  const removeNotification = useCallback((message: IMessage) => {
    headlessService.removeNotification({
      listener: () => {},
      onSuccess: () => {},
      onError: () => {},
      messageId: message._id,
    });
  }, [])
  // TODO poner useCallback en todas
  const markAsAllSeen = useCallback(() => {
      const ids = notifications.filter((n) => !n.seen).map((n) => n._id)
      ids.length > 0 && headlessService.markNotificationsAsSeen({
        listener: () => {},
        onSuccess: () => {},
        onError: () => {},
        messageId: ids,
      });
  
    }, [notifications])

  const updateUserSettings = useCallback((id: string, channel: string, value: boolean) => {
    headlessService.updateUserPreferences({
      listener: () => {},
      onSuccess: () => {
        fetchUserSettings()
      },
      onError: (error: unknown) => {
        console.error(error);
      },
      templateId: id,
      channelType: channel,
      checked: value,
    });
  }, [])

  const fetchUserSettings = useCallback(() => {
    headlessService.fetchUserPreferences({
      listener: () => {},
      onSuccess: (settings: IUserPreferenceSettings[]) => {
        setSettings(settings)
      },
      onError: () => {},
    });

  }, [])

  return {
      isLoading,
      notifications,
      page,
      totalCount,
      pageSize,
      hasMore,
      unSeenCount,
      unReadCount,
      settings,
      isAll,
      fetchMore,
      markAsRead,
      removeNotification,
      markAsAllRead,
      markAsAllSeen,
      updateUserSettings,
      fetchUserSettings,
      fetchAllNotifications,
      fetchUnReadNotifications
  }
  }