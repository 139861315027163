import { Icon, Text, Dropdown } from '../common'
import { useNotifications } from './NotificationsHook'
import { useEffect, useMemo, useState } from 'react'
import { NotificationsPanel } from './NotificationsPanel'
import { SettingsPanel } from './SettingsPanel'
import { userInfoData } from '../../hooks/useAuth'

enum panelType {
  notifications = 'not',
  settings = 'set'
}

export const Inbox= ({ userInfo } : { userInfo : userInfoData}) => {
  const {
    isLoading,
    notifications,
    hasMore,
    unReadCount,
    unSeenCount,
    settings,
    isAll,
    fetchMore,
    markAsRead,
    markAsAllRead,
    markAsAllSeen,
    removeNotification,
    updateUserSettings,
    fetchUserSettings,
    fetchAllNotifications,
    fetchUnReadNotifications
  } = useNotifications(userInfo.user_uuid)
  
  const [panelTypeState, setPanelTypeState] = useState<panelType>(panelType.notifications)
  useEffect(() => {
    if (panelTypeState === panelType.settings) {
      fetchUserSettings()
    }
  }, [panelTypeState])

  const panel = useMemo(() => {
    return (
      <div className="config-dropdown-prepend">
          <div className="notifications-inbox">
          {
            panelTypeState === panelType.notifications && 
            (<NotificationsPanel
              unSeenCount={unSeenCount}
              notifications={notifications}
              isLoading={isLoading}
              hasMore={hasMore}
              loadMore={fetchMore}
              readAll={markAsAllRead}
              markAsRead={markAsRead}
              removeNotification={removeNotification}
              markAsAllSeen={markAsAllSeen}
              onSettingClick={() => setPanelTypeState(panelType.settings)}
              fetchAllNotifications={fetchAllNotifications}
              fetchUnReadNotifications={fetchUnReadNotifications}
              isAll={isAll}
              userInfo={userInfo}
            />)
          }
          {  
            panelTypeState === panelType.settings &&
            (<SettingsPanel
              settings={settings}
              onNotificationClick={() => setPanelTypeState(panelType.notifications)}
              updateUserSettings={updateUserSettings}
              userInfo={userInfo}
            />)
          }
          </div>
      </div>
    )
  },[hasMore, notifications, unSeenCount, unReadCount, isLoading, panelTypeState, settings, isAll, userInfo])

  return (
    <Dropdown
      className={['context-menu-notifications']}
      prepend={panel}
      width={500}
      bgColor="white"
      color="black"
      offsetY={10}
    > 
      <Bell
        unReadCount={unReadCount}
        unSeenCount={unSeenCount}
      />
    </Dropdown>
  )
}


const Bell = ({
  unReadCount,
  unSeenCount
} : {
  unReadCount: number
  unSeenCount: number
}) => {
  return (
    <span className="bell">
    <Icon name="icon-bell-outline"
      style={
        unSeenCount > 0
        ? { color: 'red'}
        : {}
      } 
      size="xl"
    />
    { unReadCount > 0 && (<Text
      className="topbar-btn-bullet"
      bgColor="primary"
      color="white"
    >
      {(unReadCount > 99) ? '99+' : unReadCount}
    </Text>)}
  </span>
  )
}


