
import { IUserPreferenceSettings, ChannelTypeEnum  } from '@novu/headless';

import { Icon, Text, Box, Switch } from '../common'
import { userInfoData } from '../../hooks/useAuth';

export const SettingsPanel = ({
    settings,
    onNotificationClick,
    updateUserSettings,
    userInfo
}: {
    settings: IUserPreferenceSettings[]
    onNotificationClick: () => void
    updateUserSettings: (id: string, channel: string, value: boolean) => void
    userInfo: userInfoData
}) => {
    return (
        <div>
        <Header
          onNotificationClick={onNotificationClick}
          userInfo={userInfo}
        />
        <Body
          settings={settings}
          updateUserSettings={updateUserSettings}
          userInfo={userInfo}
        />
      </div>
    )
}

const Header = ({
  onNotificationClick,
  userInfo
}: {
  onNotificationClick: () => void
  userInfo: userInfoData
}) => {
    return (
    <Box bgColor="primary" className='notifications-header'>
      <Box onClick={onNotificationClick} className='notification-hader-right'>
      <Icon
          style={{
            transform: 'rotate(180deg)',
            color: 'white',
            marginBottom: '-4px'
          }}
          size={30}
          name="icon-chevron-right"></Icon>
        <Text
          color="white"
          size="xl"
          weight="bold"
          underline={true}
          style={{ cursor:'pointer'}}
        >{userInfo.translations.notifications_settings_title}</Text>
      </Box>
    </Box>
    )
}

const Body = ({
  settings,
  updateUserSettings,
  userInfo
}: {
  settings: IUserPreferenceSettings[]
  updateUserSettings: (id: string, channel: string, value: boolean) => void
  userInfo: userInfoData
}) => {
  
  return (
  <Box className="notifications-body"> 
    <Text
    weight="bold"
    size="xl"
    style={{
      marginBottom: '20px'
    }}
    tag="div"
    >{userInfo.translations.notifications_settings_subtitle}
   </Text>
  {
    settings
      .filter((s) => !s.template.critical && Object.keys(s.preference.channels).length > 0)
      .map((item: IUserPreferenceSettings) => {
      return (
        <SettingsItem
          key={item.template._id}
          item={item}
          updateUserSettings={updateUserSettings}
        ></SettingsItem>
      )
    })
  } 
  </Box>)
}

const SettingsItem = ({
  item,
  updateUserSettings
}: {
  item: IUserPreferenceSettings
  updateUserSettings: (id: string, channel: string, value: boolean) => void

}) => {
  const onChangeInput = (id: string, channel: string, value: boolean) => {
    updateUserSettings(id, channel, value)
  }
  return (
    <Box
      className="notifications-settings-template"
    >
     <Text
      size="xl"
     > {item.template.name}</Text>
      <Box
        className="notifications-settings-channels"
      >
      {
        Object.keys(item.preference.channels).map((channel: string) => {
          const channelTyped: ChannelTypeEnum = channel as ChannelTypeEnum;
          return (
            <Box
              key={`${item.template._id}-${channel}`}
              className="notifications-settings-channels-item"
            >
            <Text class="channel-name" size="md">{channelTyped.replace('_', ' ')}</Text>
            <Switch
              onChange={value => onChangeInput(item.template._id, channel, value)}
              checked={item.preference.channels[channelTyped]}
            />
            {/* <label
            >
              <Text class="channel-name" size="md">{channelTyped.replace('_', ' ')}</Text>
              <input
                type='checkbox'
                onChange={e => onChangeInput(item.template._id, channel, e.target.checked)}
                defaultChecked={item.preference.channels[channelTyped]}/>
            </label> */}
            </Box>
          )
        })

      }
      </Box>
   </Box>
  )
}