
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
export const Switch = ({
  onChange,
  checked
}:{
  onChange: (e: boolean) => void
  checked?: boolean | undefined
}) => {
    const [checkInternal, setCheckInternal ] = useState(checked)
    const internalOnChange = useCallback((e: ChangeEvent<HTMLInputElement>)=> {
      setCheckInternal(e.target.checked)
      onChange(e.target.checked)
    }, [checked])

    useEffect(() => {
      setCheckInternal(checked)
    }, [checked])
    return (
      <label className="switch">
        <input type="checkbox" checked={checkInternal} onChange={(e) => internalOnChange(e)}/>
        <span className="slider round"></span>
      </label>
    )
}