import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Box } from '../index'
import { DropdownItem } from './DropdownItem'
import type { BoxProps } from '../Box'
import type { DropdownItemProps as DropdownItemPropsBase } from './DropdownItem'
import { convertToUnit } from '../../../utils'

export type DropdownItemProps<Dropdown> = DropdownItemPropsBase & {
  dropdown?: Dropdown
}

export type DropdownProps = BoxProps & {
  contentClass?: any,
  items?: DropdownItemProps<DropdownProps>[],
  prepend?: React.FC,
  append?: React.FC,
  position?: 'left' | 'right' | 'top' | 'bottom',
  align?: 'start' | 'center' | 'end',
  offsetY?: string | number | null,
  offsetX?: string | number | null,
  activeMode?: 'click' | 'hover',
  onClick?: (e: Event) => void,
  onClickItem?: (item: DropdownItemPropsBase, e: Event) => void
  onActiveChange?: (value: boolean) => void 
}

type offset = {
  paddingLeft?: string,
  paddingRight?: string,
  paddingTop?: string,
  paddingBottom?: string
}

export const Dropdown = React.forwardRef(function Dropdown ({
  className,
  style,
  contentClass,
  children,
  prepend,
  append,
  items = [],
  position = 'bottom',
  align = 'start',
  offsetX = 0,
  offsetY = 0,
  activeMode = 'click',
  onClick,
  onClickItem,
  onFocus,
  onBlur,
  bgColor = 'background',
  color = 'base',
  onActiveChange,
  ...props
} : DropdownProps, ref) {

  const rootRef = useRef<HTMLSpanElement | null>(null)
  const contentRef = useRef<HTMLSpanElement | null>(null)

  const getRootRef = (node: HTMLSpanElement | null) => {
    try {
      rootRef.current = node;
      if (typeof ref === 'function') ref(node)
      else if (ref) ref.current = node
    } catch {}
  }

  const [active, setActive] = useState<boolean>(false)
  const [visibility, setVisibility] = useState<'hidden' | 'visible'>('hidden')
  
  useEffect(() => {
    onActiveChange && onActiveChange(active)
  },[active]) // <--
  
  const cleanItem = (item: DropdownItemProps<DropdownProps>) => {
    item = { ...item }
    delete item.dropdown
    return item
  }

  useEffect(() => {
    if (active) return
    setVisibility('hidden')
  }, [active])

  // Hover mode

  const onMouseover = useCallback(() => {
    const activeElement = document.activeElement as HTMLElement
    activeElement?.blur() // Close dropdown
    setActive(true)
  }, [setActive])

  const onMouseleave = useCallback(() => setActive(false), [setActive])

  // Click mode

  const clickHandler = useCallback((e: Event) => {
    onClick && onClick(e)
    setActive(true)
  }, [onClick, setActive])

  const clickItemHandler = useCallback((item: DropdownItemProps<DropdownProps>, e: Event) => {
    e.stopPropagation()
    onClickItem && onClickItem(item, e)
  }, [onClickItem])

  const onFocusHandler = useCallback((e: FocusEvent) => {
    onFocus && onFocus(e)
    setActive(true)
  }, [onFocus, setActive])

  const onBlurHandler = useCallback((e: FocusEvent) => {
    if (rootRef.current?.contains(e.relatedTarget as Node) || !document.hasFocus()) return
    onBlur && onBlur(e)
    setActive(false)
  }, [onBlur, rootRef, setActive])

  // Position control

  const [internalPosition, setInternalPosition] = useState<string>(position)
  const [internalAlign, setInternalAlign] = useState<string>(align)
  const [offset, setOffset] = useState<offset>({})

  const calcOffset = useCallback(() => {
    const obj: offset = {}
    if (internalPosition === 'top') {
      obj.paddingBottom = convertToUnit(offsetY)
    } else if (internalPosition === 'bottom') {
      obj.paddingTop = convertToUnit(offsetY)
    } else if (internalAlign === 'end') {
      obj.paddingBottom = convertToUnit(offsetY)
    } else {
      obj.paddingTop = convertToUnit(offsetY)
    }
    if (internalPosition === 'right') {
      obj.paddingLeft = convertToUnit(offsetX)
    } else if (internalPosition === 'left') {
      obj.paddingRight = convertToUnit(offsetX)
    } else if (internalAlign === 'end') {
      obj.paddingRight = convertToUnit(offsetX)
    } else {
      obj.paddingLeft = convertToUnit(offsetX)
    }
    setOffset(obj)
  }, [internalPosition, internalAlign, setOffset])

  const getRect = useCallback((node: HTMLElement | null = null) => {
    return node?.getBoundingClientRect() ?? {
      top: 0,
      left: 0,
      bottom: window.innerHeight,
      right: window.innerWidth,
      width: window.innerWidth,
      height: window.innerHeight
    }
  }, [])

  const calcBestPosition = () => {
    if (!contentRef.current || !active) return

    const containerRect = getRect()
    const rect = getRect(contentRef.current)

    const spaceTop = rect.top - containerRect.top
    const spaceBottom = containerRect.bottom - rect.bottom
    const spaceLeft = rect.left - containerRect.left
    const spaceRight = containerRect.right - rect.right

    if (['top', 'bottom'].includes(internalPosition)) {
      if (spaceTop < 0) setInternalPosition('bottom')
      else if (spaceBottom < 0) setInternalPosition('top')
      if (spaceLeft < 0) setInternalAlign('start')
      else if (spaceRight < 0) setInternalAlign('end')
    } else {
      if (spaceLeft < 0) setInternalPosition('right')
      else if (spaceRight < 0) setInternalPosition('left')
      if (spaceTop < 0) setInternalAlign('start')
      else if (spaceBottom < 0) setInternalAlign('end')
    }    
    window.requestAnimationFrame(() => {
      setVisibility('visible')
    })
  }

  useEffect(() => {
    setInternalPosition(position)
  }, [position])

  useEffect(() => {
    setInternalAlign(align)
  }, [align])

  useEffect(() => {
    calcBestPosition()
  }, [active, contentRef, getRect, internalPosition, internalAlign])

  useEffect(() => {
    calcOffset()
  }, [internalPosition, internalAlign])

  useEffect(() => {
    window.addEventListener('resize', calcBestPosition)
    return () => window.removeEventListener('resize', calcBestPosition)
  }, [])

  return (
    <Box      
      tag="span"
      ref={getRootRef}
      tabIndex="0"
      className={['topbar-dropwdown', activeMode, active && 'active', className]}
      style={style}
      onClick={clickHandler}
      onFocus={onFocusHandler}
      onBlur={onBlurHandler}
      onMouseOver={activeMode === 'hover' ? onMouseover : undefined}
      onMouseLeave={activeMode === 'hover' ? onMouseleave : undefined}
    >
      {children}
      {active && <div
        className={`topbar-dropwdown-container cursor-default position-${internalPosition} align-${internalAlign}`}
        style={{ ...offset, visibility }}
      >
        <Box
          ref={contentRef}
          {...props}
          className={['topbar-dropwdown-content', contentClass]}
          bgColor={bgColor}
          color={color}
        >
          {prepend}
          {items.map((itemProps, index) => (
            itemProps.dropdown
              ? (
                <Dropdown
                  activeMode={activeMode}
                  onClickItem={onClickItem}
                  bgColor={bgColor}
                  color={color}
                  {...props}
                  {...itemProps.dropdown}
                  key={itemProps.id || index}
                >
                  <DropdownItem
                    {...cleanItem(itemProps)}
                    appendIcon="icon-chevron-right"
                  />
                </Dropdown>
              ) : (
                <DropdownItem
                  {...itemProps}
                  key={itemProps.id || index}
                  onClick={(e: Event) => clickItemHandler(itemProps, e)}
                />
              )
          ))}
          {append}
        </Box>
      </div>}
    </Box>
  )
}) 