import { IMessage } from '@novu/headless';
import { useEffect } from 'react'
import { Icon, Text, Box } from '../common'
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { userInfoData } from '../../hooks/useAuth';

export const NotificationsPanel = ({
    unSeenCount,
    notifications,
    isLoading,
    hasMore,
    isAll,
    loadMore,
    readAll,
    markAsRead,
    removeNotification,
    markAsAllSeen,
    onSettingClick,
    fetchAllNotifications,
    fetchUnReadNotifications,
    userInfo
  }: {
    unSeenCount: number
    notifications: IMessage[] | undefined,
    isLoading: boolean
    hasMore: boolean
    isAll: boolean
    loadMore: () => void
    readAll: () => void
    markAsRead: (notificaion: IMessage) => void
    removeNotification: (notificaion: IMessage) => void
    markAsAllSeen: () => void
    onSettingClick: () => void
    fetchAllNotifications: () => void
    fetchUnReadNotifications: () => void
    userInfo: userInfoData
  }) => {
    return (
      <div>
        <Header
          readAll={readAll}
          unSeenCount={unSeenCount}
          onSettingClick={onSettingClick}
          userInfo={userInfo}
        />
        <Body
          notifications={notifications}
          isLoading={isLoading}
          hasMore={hasMore}
          loadMore={loadMore}
          markAsRead={markAsRead}
          removeNotification={removeNotification}
          markAsAllSeen={markAsAllSeen}
          fetchAllNotifications={fetchAllNotifications}
          fetchUnReadNotifications={fetchUnReadNotifications}
          isAll={isAll}
          userInfo={userInfo}
        />
      </div>
    );
  }
  
  
  const Header = ({
    readAll,
    unSeenCount,
    onSettingClick,
    userInfo
  }: {
    readAll: () => void,
    unSeenCount: number,
    onSettingClick: () => void,
    userInfo: userInfoData
  }) => {
    return (
      <Box bgColor="primary" className='notifications-header'>
        <Text
          color="white"
          weight="bold"
          size="xl"
          >{userInfo.translations.notifications_inbox_title}
          { unSeenCount > 0 && (
            <Text
            className="notifications-unseen"
            bgColor="primary"
            color="white"
            >
            {(unSeenCount > 99) ? '99+' : unSeenCount}
          </Text>)}
        </Text>
        <Box className='notification-hader-right'>
          <Text
            color="white"
            size="base"
            weight="bold"
            underline={true}
            style={{ cursor:'pointer'}}
            onClick={readAll}
          >{userInfo.translations.notifications_set_as_read}</Text>
          <Icon
            onClick={onSettingClick}
            title="Ajustes"
            color="white"
            className="notifications-settings"
            name="icon-settings-2-outline"
            style={{ cursor:'pointer'}}
          />
        </Box>
      </Box>
    )
  }
  
  const Body = ( {
    notifications,
    isLoading,
    hasMore,
    isAll,
    userInfo,
    loadMore,
    markAsRead,
    removeNotification,
    markAsAllSeen,
    fetchAllNotifications,
    fetchUnReadNotifications
  }: {
    notifications: IMessage[] | undefined,
    isLoading: boolean,
    hasMore: boolean
    isAll: boolean
    userInfo: userInfoData
    loadMore: () => void
    markAsRead: (notification: IMessage) => void
    removeNotification: (notification: IMessage) => void
    markAsAllSeen: () => void
    fetchAllNotifications: () => void
    fetchUnReadNotifications: () => void
  } ) => {
    useEffect(() => {
      return () => {
        markAsAllSeen()
      }
    }, [notifications])
    const [sentryRef] = useInfiniteScroll({
      loading: isLoading ,
      hasNextPage: hasMore,
      onLoadMore: loadMore,
      rootMargin: '0px 0px 20px 0px',
    });
    return (
      <Box
        className='notifications-body'
      >
      {
        isAll && (
          <Box className="notifications-filters">
            <Text className="active-filter" weight="bold" underline={true} size="lg" onClick={fetchUnReadNotifications}>{userInfo.translations.notifications_back_to_unread}</Text>
          </Box>
        )
      }
      {
        isLoading && (<h5>Is Loading</h5>)
      }
      {
        Array.isArray(notifications) && notifications?.length > 0 && (
          <div>
            {notifications?.map((notification: IMessage) => {
              return (
                <NotificationItem
                  key={notification._id}
                  notification={notification}
                  markAsRead={markAsRead}
                  removeNotification={removeNotification}
                />
              )
            })}
            {(isLoading || hasMore) && (
              <div ref={sentryRef}>
                {userInfo.translations.notifications_loading}
              </div>
            )}
          </div>
        )
        
      }
      {
        !Array.isArray(notifications) || notifications.length === 0 && (
          <Box
            className="notification-empty-message"
          >
              { isAll && (<Text> {userInfo.translations.notifications_empty_notifications}</Text>)}
              { !isAll && (<Text> {userInfo.translations.notifications_empty_unread_notifications}</Text>)}
          </Box>
        )
        
      }
      {
      !isAll && (
          <Box className="notifications-filters">
            <Text className="active-filter" weight="bold" underline={true} size="lg" onClick={fetchAllNotifications}>{userInfo.translations.notifications_go_to_all}</Text>
          </Box>
        )
      }
    </Box>
  
    )
  }
  
  const NotificationItem = ({
    notification,
    markAsRead,
    removeNotification
  }: {
    notification: IMessage,
    markAsRead: (notification: IMessage) => void
    removeNotification: (notification: IMessage) => void
  }) => {
    return (
      <Box
        className={
          `notifications-notification ${(notification.read) ? 'is-read' : 'not-read'} ${(notification.seen) ? 'is-seen' : 'not-seen' }`
        }
      >
        <Box className="dot"></Box>
        <Box className="avatar" style={{backgroundImage: `url("${notification.actor?.data}")`}}></Box>
        <Box className='content'>
          <Text>{notification.content}</Text>

          {
            notification.cta && notification.cta?.action?.buttons && (
              <Box className="button-box">
                {
                  notification.cta?.action?.buttons.map((button) => {
                    return (
                      <Text
                        key={button.content}
                        color={button.type}
                        underline={true}
                        weight="bold"
                        className="action-button"
                        onClick={() => {
                          console.log('action click: '+ JSON.stringify(button, null, 4))
                        }}
                      >
                        {button.content}
                      </Text>  
                    )
                  })
                }
              </Box>
            )
          }
          <Box></Box>
        </Box>

        <Box className='actions'>
          
          {
            !notification.read &&
            (<Icon
              onClick={() => markAsRead(notification)}
              title="Marcar como leída"
              className="close"
              name="icon-checkmark-outline"
              size="xl"
            />)
          }
          {
            notification.read &&
            (<Icon
              onClick={() => removeNotification(notification)}
              title="Eliminar notificación"
              className="close"
              name="icon-close"
              size="xl"
            />)

          }
        </Box>
      </Box>
    )
  }